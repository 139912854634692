
import { Vue, Component } from 'vue-property-decorator';
import HomeUpperSection from '@/components/views/home/HomeUpperSection.vue';
import ProjectsTableWrapper from '@/components/global/table-wrappers/ProjectsTableWrapper.vue';
import { UserRepository } from '@/repositories/UserRepository';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';
import i18n from '@/i18n';
import { generateSearchMessage, prepareAndPrintTable } from '@/helpers/TableHelper';
import TableSearchBar from '@/components/global/TableSearchBar.vue';
import { getLastSelectedTab } from '@/helpers/project/ProjectHelper';
import HomeTopBar from '../components/views/home/HomeTopBar.vue';

@Component({
    name: 'Home',
    components: {
        TableSearchBar,
        HomeUpperSection,
        ProjectsTableWrapper,
        HomeTopBar,
    },
})
export default class Home extends Vue {
    private entityType = getLastSelectedTab();
    private search = {
        isActive: true,
    };
    private isDeleteMode = false;
    private numberOfTotalItems = 0;
    private loadingOverlay = new LoadingOverlayHelper(this, {});
    private pageTitle: string = this.entityType as string;
    private isLoading: boolean = false;
    private fromFilter: string = 'all';

    private async onPrint() {
        if (this.currentUser == null || this.entityType == null) {
            return;
        }

        if (this.numberOfTotalItems > 10000) {
            this.$notification.error({
                message: i18n.t('Too many projects!') as string,
                description: i18n.t('Maximum number of projects for printing is 10000') as string,
            });
            return;
        }

        this.startLoaderAndNotifyUserForPrinting();
        await this.$nextTick();
        await prepareAndPrintTable(
            this.entityType as string,
            this.currentUser.id,
            // @ts-ignore
            this.$htmlToPaper,
            this.showSourceTabs ? this.fromFilter : undefined
        );
        this.loadingOverlay.stop();
    }

    private startLoaderAndNotifyUserForPrinting() {
        this.loadingOverlay.start();
        this.$notification.info({
            message: i18n.t('Generiranje liste projekata je započeto') as string,
            description: i18n.t('Molimo za strpljenje i da ne gasite ovu karticu') as string,
        });
    }

    private get showSourceTabs() {
        const groupId = String(this.$store.getters['jwtData/currentUserGroup']);
        return (
            (this.entityType === 'Vsi projekti' || this.entityType === 'Ponudbe' || this.entityType === 'Naročila') &&
            groupId !== '1'
        );
    }

    private get searchMessage() {
        return generateSearchMessage(this.numberOfTotalItems);
    }

    private get currentUser() {
        return UserRepository.getCurrentUser();
    }

    private onUpdateEntityType(entityType: string) {
        switch (entityType) {
            case 'AllProjects': {
                this.pageTitle = i18n.t('Svi projekti');
                break;
            }
            case 'Naročila': {
                this.pageTitle = i18n.t('Narudžbe');
                break;
            }
            case 'Ponudbe': {
                this.pageTitle = i18n.t('Ponude');
                break;
            }
            case 'Povpraševanja': {
                this.pageTitle = i18n.t('Upit');
                break;
            }
            case 'Upravljalna plošča': {
                this.pageTitle = i18n.t('Admin');
                break;
            }
            case 'RoltekRequest': {
                this.pageTitle = i18n.t('RoltekRequest');
                break;
            }
            default: {
                this.pageTitle = entityType;
                break;
            }
        }
        this.entityType = entityType;
    }

    private onUpdateIsDeleteMode(data: { isDeleteMode: boolean; selectedProjects: string[] }) {
        this.isDeleteMode = data.isDeleteMode;
    }

    private onUpdatePagination({ totalNumber }: { totalNumber: number }) {
        if (totalNumber == null) {
            return;
        }

        this.numberOfTotalItems = totalNumber;
    }

    private updateFromFilter(fromFilter: string) {
        if (fromFilter) {
            this.fromFilter = fromFilter;
        }
    }

    private async mounted() {
        EventBus.$on(EventBusEvents.updateIsDeleteMode, this.onUpdateIsDeleteMode);
        EventBus.$on(EventBusEvents.emitProjectPagination, this.onUpdatePagination);
        EventBus.$on(EventBusEvents.projectsFromFilterChanged, this.updateFromFilter);
    }

    private async beforeDestroy() {
        EventBus.$off(EventBusEvents.updateIsDeleteMode, this.onUpdateIsDeleteMode);
        EventBus.$off(EventBusEvents.emitProjectPagination, this.onUpdatePagination);
        EventBus.$off(EventBusEvents.projectsFromFilterChanged, this.updateFromFilter);
    }
}
